// Varicent Confidential
// © Copyright Varicent Parent Holdings Corporation 2020
// The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.

import {
	SYSTEM_TABLE_DICTIONARIES,
	SYSTEM_COLUMN_DICTIONARIES,
	REVERSED_SYSTEM_COLUMN_DICTIONARIES,
} from '../../constants/localeDefaults';

export function setTranslations(
	tableNames,
	reversedTableNames,
	columnNames,
	reversedColumnNames,
	calendarNames,
	reversedCalendarNames,
	calendarNamesTranslations
) {
	window.tableNames = tableNames;
	window.reversedTableNames = reversedTableNames;
	window.columnNames = columnNames;
	window.reversedColumnNames = reversedColumnNames;
	window.calendarNames = calendarNames;
	window.reversedCalendarNames = reversedCalendarNames;
	window.calendarNamesTranslations = calendarNamesTranslations;
}

export function translationsLoaded() {
	return (
		window.tableNames &&
		window.reversedTableNames &&
		window.columnNames &&
		window.reversedColumnNames &&
		window.calendarNames &&
		window.reversedCalendarNames &&
		window.calendarNamesTranslations
	);
}

export function translateTableName(id) {
	const val = window.tableNames ? window.tableNames[id] : null;
	return val || id;
}

export function translateColumnName(id, removeSpaces = true) {
	const val = window.columnNames ? window.columnNames[id] : null;
	if (val) {
		// Remove spaces if the column name can be saved back to the database
		return removeSpaces ? val.replace(/ /g, '_') : val;
	} else {
		return id;
	}
}

export function translateColumnNameToLanguage(
	id,
	languageCode = 'en',
	removeSpaces = true
) {
	const val = SYSTEM_COLUMN_DICTIONARIES[languageCode]
		? SYSTEM_COLUMN_DICTIONARIES[languageCode][id]
		: null;
	if (val) {
		// Remove spaces if the column name can be saved back to the database
		return removeSpaces ? val.replace(/ /g, '_') : val;
	} else {
		return id;
	}
}

export function translateCalendarName(id) {
	const val = window.calendarNames ? window.calendarNames[id] : null;
	return val || id;
}

export function translateTableOrColumnName(id) {
	const table = translateTableName(id);
	if (table !== id) return table;

	return translateColumnName(id);
}

export function translatePath(path, containsTable = true) {
	if (!path || typeof path !== 'string') return path;

	let toReturn = '';
	const splits = path.split('.');
	if (splits.length === 1) {
		toReturn = containsTable
			? translateTableOrColumnName(splits[0])
			: translateColumnName(splits[0]);
	} else {
		for (let i = 0; i < splits.length; i++) {
			if (i === 0) {
				toReturn = translateTableName(splits[i]);
			} else {
				toReturn = `${toReturn}.${translateColumnName(splits[i])}`;
			}
		}
	}
	return toReturn;
}

export const translateMessage = (message) => {
	if (!message) return message;
	const split = message.replace('\r\n', ' +++\r\n+++ ').split(' ');

	let sb = '';
	for (let i = 0; i < split.length; i++) {
		let toAppend = split[i];

		const sentence = split[i].endsWith('.');

		// \u3002 is the unicode code for the Chinese period
		const chineseSentence = split[i].endsWith('\u3002');

		if (sentence || chineseSentence) {
			toAppend = toAppend.substring(0, split[i].length - 1);
		}

		const doubleQuotes =
			toAppend.startsWith("'") && toAppend.endsWith("'") && toAppend.length > 1;
		const sQuotes =
			toAppend.startsWith("'") && toAppend.endsWith("'") && toAppend.length > 1;
		const possessive = toAppend.endsWith("'s");
		const colon = toAppend.endsWith(':');
		const comma = toAppend.endsWith(',');
		// Special treatment for Chinese fullwidth comma character
		const chineseComma = toAppend.endsWith('\uFF0C');
		const brackets = toAppend.startsWith('(') && toAppend.endsWith(')');
		const sBrackets = toAppend.startsWith('[') && toAppend.endsWith(']');
		const sPath =
			toAppend.indexOf('.') > -1 &&
			!toAppend.startsWith('.') &&
			!toAppend.endsWith('.');

		if (sQuotes || brackets || sBrackets || doubleQuotes) {
			toAppend = toAppend.substring(1, toAppend.length - 1);
			toAppend = translateMessage(toAppend);
		} else if (possessive) {
			toAppend = toAppend.substring(0, toAppend.length - 2);
			toAppend = translateMessage(toAppend);
		} else if (colon || comma || chineseComma) {
			toAppend = toAppend.substring(0, toAppend.length - 1);
			toAppend = translateMessage(toAppend);
		}

		if (sPath) {
			toAppend = translatePath(toAppend);
		}

		toAppend = translateTableOrColumnName(toAppend);

		if (sQuotes) {
			toAppend = `'${toAppend}'`;
		} else if (doubleQuotes) {
			toAppend = `'${toAppend}'`;
		} else if (possessive) {
			toAppend += "'s";
		} else if (colon) {
			toAppend += ':';
		} else if (brackets) {
			toAppend = `(${toAppend})`;
		} else if (sBrackets) {
			toAppend = `[${toAppend}]`;
		} else if (comma) {
			toAppend += ',';
		} else if (chineseComma) {
			toAppend += '\uFF0C';
		}

		if (chineseSentence) {
			toAppend += '\u3002';
		}

		if (sentence) {
			toAppend += '.';
		}

		sb = `${sb + toAppend} `;
	}
	return sb.substring(0, sb.length - 1).replace(' +++\r\n+++ ', '\r\n');
};

export function reverseTranslateTableName(id) {
	const val = window.reversedTableNames ? window.reversedTableNames[id] : null;
	return val || id;
}

export function reverseTranslateColumnName(id) {
	const val = window.reversedColumnNames
		? window.reversedColumnNames[id]
		: null;
	return val || id;
}

export function reverseTranslateColumnNameGivenLanguage(
	id,
	languageCode = 'en'
) {
	const val = REVERSED_SYSTEM_COLUMN_DICTIONARIES[languageCode]
		? REVERSED_SYSTEM_COLUMN_DICTIONARIES[languageCode][id]
		: null;
	return val || id;
}

export function reverseTranslateCalendarName(id) {
	const val = window.reversedCalendarNames
		? window.reversedCalendarNames[id]
		: null;
	return val || id;
}

export function crossLanguageReverseTranslateCalendarName(id) {
	const val = window.calendarNamesTranslations
		? window.calendarNamesTranslations[id]
		: null;
	return val || id;
}

export function reverseTranslateTableOrColumnName(id) {
	const table = reverseTranslateTableName(id);
	if (table !== id) return table;

	return reverseTranslateColumnName(id);
}

export function reverseTranslatePath(path) {
	if (!path) return path;

	let toReturn = '';
	const splits = path.split('.');
	if (splits.length === 1) {
		toReturn = reverseTranslateTableOrColumnName(splits[0]);
	} else {
		for (let i = 0; i < splits.length; i++) {
			if (i === 0) {
				toReturn = reverseTranslateTableName(splits[i]);
			} else {
				toReturn = `${toReturn}.${reverseTranslateTableOrColumnName(
					splits[i]
				)}`;
			}
		}
	}
	return toReturn;
}

function initializeSystemReservedTableNames() {
	const systemReservedTableNames = {};
	for (const locale in SYSTEM_TABLE_DICTIONARIES) {
		if (SYSTEM_TABLE_DICTIONARIES.hasOwnProperty(locale)) {
			const dictionary = SYSTEM_TABLE_DICTIONARIES[locale];
			for (const prop in dictionary) {
				if (dictionary.hasOwnProperty(prop)) {
					systemReservedTableNames[prop] = true;
					systemReservedTableNames[dictionary[prop]] = true;
				}
			}
		}
	}

	window.systemReservedTableNames = systemReservedTableNames;
}

export function isSystemReservedTableName(name) {
	if (!window.systemReservedTableNames) {
		initializeSystemReservedTableNames();
	}
	return !!window.systemReservedTableNames[name];
}

export function createDocumentTitle(table, module) {
	return `${translateTableName(table)} - ${module} - SPM`;
}
